<template>
    <div>

        <v-container v-if="dashboardData" class="secondary mt-10">
            <v-row>
                <v-col>
                    <v-data-table class="guest-grid" :headers="headers" :items="filteredGuests" :search="search"
                        :footer-props="footerProps" :options.sync="options" :server-items-length="totalItems"
                        @update:options="fetchTableData" hide-default-footer>
                        <template v-slot:top>

                            <div class="input-container">
                                <div class="chart-name-container ">
                                    <p class="table-name">Прогрессивная скидка</p>
                                </div>

                                <div class="search-component">
                                    <input type="search" class="search-component__input" v-model="search"
                                        placeholder="Поиск по номеру телефона" />
                                    <svg width="17" height="17" class="search-component__icon">
                                        <use href="@/assets/icons/icons.svg#search-icon"></use>
                                    </svg>
                                </div>

                            </div>
                            <v-dialog v-if="guest" v-model="guestDialog" max-width="fit-content">
                                <v-card class="modal-card"
                                    :max-width="guest.dashboardContactBonus && Object.keys(guest.dashboardContactBonus).length != 0 ? '1120px' : '710px'">
                                    <div class=" modal-header">
                                        <h3 class="modal-title">Личные данные </h3>
                                        <v-btn @click="closeGuestDialog()" icon><v-icon>mdi-close</v-icon></v-btn>
                                    </div>
                                    <div v-if="isLoadingGuest" class="loading-message d-flex align-center justify-center">
                                        <v-progress-circular indeterminate size="40" color="primary"></v-progress-circular>
                                    </div>
                                    <div v-else class="modal-container">
                                        <div class="card-item">
                                            <div class="avatar-container">
                                                <img v-if="guestAvatarImage" v :src="guestAvatarImage"
                                                    style="border-radius: 67.5px; width:100px;  height: 100px;"
                                                    alt="Guest Avatar" />

                                                <!-- Если аватара нет, проверяем пол -->
                                                <img v-else-if="guest.gender === 'Female'"
                                                    src="@/assets/icons/Frame 7654.svg" alt="Female Guest" />

                                                <img v-else-if="guest.gender === 'Male'" src="@/assets/img/guest_man.png"
                                                    alt="Male Guest" />

                                                <!-- Если нет ни аватара, ни пола, показываем заглушку -->
                                                <img v-else src="@/assets/img/Avatar.svg" alt="Default Avatar" />
                                                <p class="card-username">{{ guest.name || 'Не указано' }}</p>

                                            </div>
                                            <div class="card-info">
                                                <div class="user-column">
                                                    <img class="small-icons" src="@/assets/img/Users.svg" alt="">
                                                    <span class="card-line"></span>
                                                </div>
                                                <div class="phone-container">
                                                    <p class="card-label">Телефон</p>
                                                    <p class="card-text">{{ formatPhoneNumber(guest.phoneNumber) ||
                                                        'Не указан' }}</p>
                                                </div>
                                                <div class="gender-container">
                                                    <p class="card-label">Пол</p>
                                                    <p class="card-text">{{ guest.gender === 'Male' ? 'Мужской' :
                                                        guest.gender === 'Female'
                                                            ? 'Женский' : 'Не указан' }}</p>
                                                </div>
                                                <div class="birthday-container">
                                                    <p class="card-label">Дата рождения</p>
                                                    <p class="card-text">{{ formatDate(guest.birthDate) }}</p>
                                                </div>
                                                <div class="user-column">
                                                    <img class="small-icons" src="@/assets/img/iPhone.svg" alt="">
                                                    <span class="card-line"></span>
                                                </div>
                                                <div class="register-container">
                                                    <p class="card-label">Дата регистрации</p>
                                                    <p class="card-text">{{ formatDate(guest.registrationDate) }}
                                                    </p>
                                                </div>

                                                <div class="user-column">
                                                    <img class="small-icons" src="@/assets/img/Bag 3.svg" alt="">
                                                    <span class="card-line"></span>
                                                </div>
                                                <div class="first-order-container">
                                                    <p class="card-label">Дата первой покупки</p>
                                                    <p class="card-text">{{ formatDate(guest.firstOrderDate) }}</p>
                                                </div>

                                            </div>
                                        </div>

                                        <div v-if="guest.dashboardContactBonus && Object.keys(guest.dashboardContactBonus).length != 0"
                                            class="card-item-second">

                                            <div style="display:flex; align-items: center; gap: 14px;">
                                                <img src="@/assets/img/Union.svg" alt="">
                                                <p class="card2-title">Лояльность</p>
                                            </div>
                                            <span class="card-line" style="width: 360px;"></span>
                                            <div id="style-3" class="loyality-container">
                                                <div class="mt-2" v-for="(bonus, index) in processedBonuses" :key="index">
                                                    <div class="header-content">
                                                        <div class="card-bonus">
                                                            <p class="bonus-points">{{ bonus.currentPercent }}%</p>
                                                            <span class="card-bonus-text">текущая скидка</span>
                                                        </div>

                                                    </div>
                                                    <div v-if="bonus.isProgressive">
                                                        <div v-if="bonus.isMaxDiscount">
                                                            <div class="bar-container">
                                                                <div class="price-circle"></div>

                                                                <div class="progress-bar">
                                                                    <div class="progress-bar-fill"
                                                                        :style="{ width: progressValueFull + '%' }">
                                                                    </div>
                                                                </div>

                                                                <div class="price-circle"></div>

                                                            </div>
                                                            <div class="price-container">
                                                                <p>
                                                                    {{ bonus.previousSpentAmountFrom }} тг
                                                                </p>
                                                                <p>
                                                                    {{ bonus.previousSpentAmountTo }} тг
                                                                </p>
                                                            </div>
                                                            <p v-if="bonus.bonusGroupType === 'CashBack'"
                                                                class="card-cashback mt-2">

                                                                Максимальный кешбэк достигнут!
                                                            </p>
                                                            <p v-if="bonus.bonusGroupType === 'Percent'"
                                                                class="card-cashback mt-2">

                                                                Максимальная скидка достигнута!
                                                            </p>

                                                        </div>

                                                        <div v-else>
                                                            <div class="bar-container">
                                                                <div class="price-circle"></div>

                                                                <div v-if="bonus.bonusGroupType === 'CashBack'"
                                                                    class="progress-bar">
                                                                    <div class="progress-bar-fill"
                                                                        :style="{ width: progressValue(bonus) + '%' }">
                                                                    </div>
                                                                </div>
                                                                <div v-if="bonus.bonusGroupType === 'Percent'"
                                                                    class="progress-bar">
                                                                    <div class="progress-bar-fill"
                                                                        :style="{ width: progressValue(bonus) + '%' }">
                                                                    </div>
                                                                </div>

                                                                <div class="price-circle-gray"></div>

                                                            </div>
                                                            <div class="price-container">
                                                                <p>
                                                                    {{ bonus.spentAmountFrom }} тг
                                                                </p>
                                                                <p>
                                                                    {{ bonus.spentAmountTo }} тг
                                                                </p>
                                                            </div>

                                                            <p v-if="bonus.bonusGroupType === 'CashBack'"
                                                                class="price-remains">Осталось потратить
                                                                еще <span class="price-remains-bold">{{
                                                                    remainingAmount(bonus) }}</span>
                                                                тг, что
                                                                бы
                                                                увеличить постоянный кешбэк до
                                                                {{ findMatchingDiscount(bonus.dashboardBonusLines,
                                                                    bonus.currentPercent).nextLevelPercent }}%</p>
                                                            <p v-if="bonus.bonusGroupType === 'Percent'"
                                                                class="price-remains">Осталось потратить
                                                                еще <span class="price-remains-bold">{{
                                                                    remainingAmount(bonus) }}</span>
                                                                тг, что
                                                                бы
                                                                увеличить постоянную скидку до
                                                                {{ findMatchingDiscount(bonus.dashboardBonusLines,
                                                                    bonus.currentPercent).nextLevelPercent }}%</p>
                                                        </div>
                                                    </div>
                                                    <div class="divider-modal"></div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="third-container">
                                            <div class="card-item-3">
                                                <div class="title-container">
                                                    <div class="d-flex align-center flex-drirection-row">
                                                        <img src="@/assets/img/delivery-small.svg" alt="">
                                                        <p class="title-name">Статистика</p>
                                                    </div>
                                                    <div class="d-flex align-center flex-drirection-row">
                                                        <p class="review-title">Отзывы</p>
                                                        <div class="review-container">
                                                            <img class="review-img" src="@/assets/img/like-small.svg"
                                                                alt="">
                                                            <span class="review-text">{{ guest.reviewAmount
                                                            }}</span>
                                                        </div>




                                                    </div>
                                                </div>
                                                <div class="divider-modal"></div>
                                                <div class="d-flex date-containers">
                                                    <div class="date-container-1">
                                                        <p class="order-1">Дата последнего заказа</p>
                                                        <p class="date-order">{{
                                                            formatDate(guest.lastInHouseOrderDate) }}</p>
                                                    </div>
                                                    <div class="date-container-2">
                                                        <p class="order-1">Дата последней доставки</p>
                                                        <p class="date-order">{{
                                                            formatDate(guest.lastDeliveryOrderDate) }}</p>
                                                    </div>
                                                </div>
                                                <div class="order-info-container">
                                                    <!-- <div class="order-info-containers">
                                                        <p class="order-info-text-green">Кол-во</p>
                                                        <p class="order-info-text-green">Сумма</p>
                                                    </div> -->
                                                    <div class="divider-modal"></div>

                                                    <div class="mt-2">
                                                        <!-- <p class="order-title">Всего заказов</p> -->
                                                        <div class="order-info-containers-1">
                                                            <div class="d-flex flex-column">
                                                                <p class="order-title">Всего заказов</p>
                                                                <p class="order-info-text-second">{{ guest.orderAmount
                                                                }}</p>
                                                            </div>
                                                            <div class="d-flex flex-column">
                                                                <p class="order-title">Всего потраченно</p>
                                                                <p class="order-info-text-second-2">{{
                                                                    guest.spentAmount ?
                                                                    Number(guest.spentAmount.toFixed()).toLocaleString('ru-RU')
                                                                    : '0' }} ₸
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="divider-modal mt-0"></div>
                                                    <div class="mt-2">
                                                        <div class="order-info-containers-1">
                                                            <div class="d-flex flex-column">
                                                                <p class="order-title">Размер скидки</p>
                                                                <p class="order-info-text-second">{{
                                                                    guest.currentPercent ?
                                                                    Number(guest.currentPercent.toFixed()).toLocaleString('ru-RU')
                                                                    : '0' }} %
                                                                </p>
                                                            </div>
                                                            <div class="d-flex flex-column">
                                                                <p class="order-title">Предоставлено скидок</p>
                                                                <p class="order-info-text-second-2">{{
                                                                    guest.percentSaved ?
                                                                    Number(guest.percentSaved.toFixed()).toLocaleString('ru-RU')
                                                                    : '0' }} ₸ </p>
                                                            </div>
                                                            <!-- <p class="order-info-text-second">{{
                                                                guest.orderAmountInHouse }}</p>
                                                                <p class="order-title">В зале</p>
                                                            <p class="order-info-text-second">{{
                                                                guest.totalSpentInHouse ?
                                                                guest.totalSpentInHouse.toLocaleString('ru-RU') :
                                                                '0' }} ₸</p> -->
                                                        </div>
                                                    </div>
                                                    <div class="divider-modal mt-0 "></div>
                                                </div>
                                            </div>

                                            <div class="card-item-4">
                                                <div class="title-container">
                                                    <div class="d-flex align-center flex-drirection-row">
                                                        <img src="@/assets/img/map-location.svg" alt="">
                                                        <p class="title-name">Рестораны участники программы лояльности</p>
                                                    </div>

                                                </div>
                                                <div class="divider-modal"></div>
                                                <div class="location-container" id="style-2">
                                                    <div v-if="guest.restaurants && guest.restaurants.length > 0">
                                                        <div v-for="(rest, index) in guest.restaurants" :key="index"
                                                            class="location-containers">
                                                            <p class="location-name">
                                                                {{ rest.name || 'Без названия' }}
                                                            </p>
                                                            <p class="location-adress">
                                                                {{ rest.address }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p v-else class="no-address-message">В данной бонусной группе нет
                                                        ресторанов</p>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </v-card>
                            </v-dialog>


                            <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                                {{ message }}
                                <template v-slot:action="{ attrs }">
                                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                                        Закрыть
                                    </div>
                                </template>
                            </v-snackbar>
                        </template>

                        <template v-slot:item="{ item }">
                            <tr>
                                <td @click="getGuest(item)">
                                    {{ formatName(item.name) }}
                                </td>
                                <td @click="getGuest(item)">
                                    {{ formatPhoneNumber(item.phoneNumber) || " " }}
                                </td>
                                <td @click="getGuest(item)">
                                    {{ item.spentAmount ? Number(item.spentAmount.toFixed(0)).toLocaleString('ru-RU') :
                                        '0' }} ₸
                                </td>
                                <td @click="getGuest(item)">
                                    {{ item.percentSaved ? Number(item.percentSaved.toFixed(0)).toLocaleString('ru-RU') :
                                        '0' }} ₸ </td>
                                <td @click="getGuest(item)">
                                    {{ item.currentPercent ? item.currentPercent.toLocaleString('ru-RU') : '0' }} %
                                </td>
                                <td @click="getGuest(item)">
                                    {{ item.nextPercent ? item.nextPercent.toLocaleString('ru-RU') : '0' }} %
                                </td>
                                <td @click="getGuest(item)">
                                    {{ item.toNextLevelAmount ?
                                        Number(item.toNextLevelAmount.toFixed(0)).toLocaleString('ru-RU') : '0' }} ₸
                                </td>
                                <!-- <td @click="getGuest(item)">
                                    {{ item.nextPercent ? item.nextPercent.toLocaleString('ru-RU') : '0' }} %
                                </td>
                                <td @click="getGuest(item)">
                                </td> -->
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            <!-- <div v-if="showNoDataMessage">Не найденно подходящих записей.</div> -->
                            <v-progress-circular v-if="isLoadingGuest" indeterminate color="primary"></v-progress-circular>
                        </template>
                        <template v-slot:footer>
                            <div class="v-data-footer">
                                <div class="v-data-footer__select">
                                    Кол-во строк на странице:
                                    <v-select class="custom-select" v-model="itemsPerPage"
                                        :items="footerProps.itemsPerPageOptions" @change="changeItemsPerPage"
                                        hide-details></v-select>
                                </div>
                                <div class="v-data-footer__pagination">
                                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                                    {{ totalItems }}
                                </div>
                                <div class="v-data-footer__icons-before">
                                    <button type="button" :disabled="currentPage === 1"
                                        class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                        :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                                        @click="goToPreviousPage">
                                        <span class="v-btn__content">
                                            <i aria-hidden="true"
                                                class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="v-data-footer__icons-after">
                                    <button type="button" :disabled="currentPage === totalPages"
                                        class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                        :class="{ 'v-btn--disabled': currentPage === totalPages }"
                                        aria-label="Следующая страница" @click="goToNextPage">
                                        <span class="v-btn__content">
                                            <i aria-hidden="true"
                                                class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import moment from "moment";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { mapState, mapActions } from 'vuex';
export default {
    directives: { mask },
    name: "discountProgressiveTableChart",
    props: {
        dashboardData: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
        // totalItems: { type: Number, required: true },
        // isLoading: { type: Boolean, default: false },
        // options: { type: Object, required: true },
    },


    data: () => ({
        isDownloading: false,
        isDataLoaded: false,
        formattedDataReady: false,
        formattedData: [],
        downLoadOrderData: {
            items: [],
        },
        awaitFormattedData: [],

        selectedRestaurantName: '',
        imageHost: process.env.VUE_APP_IMAGE_API_HOST,
        progressValueFull: 100,
        restaurantsList: [],
        dashboardInfo: {},
        sortOrder: {},
        showArrows: null,
        guestData: {},
        guest: {},
        // contactAddressList: [],
        guestDialog: false,
        activeBlock: 0,
        noDataMessage: "",
        selectedRestaurant: null, // Хранит выбранный ресторан
        // isLoading: true,
        // isLoadingGuest: true,
        dialog: false, // Скрываем календарь по умолчанию
        selectedDate: moment(new Date()).format('YYYY-MM-DD'),
        workStartDate: "", // Текущая выбранная дата
        dateType: "date", // Выбор по умолчанию: месяц
        dataTypePeriod: "",
        formattedDateRange: "",
        formattedDateRangeProd: "",
        formattedCompareDataRange: "",
        formattedCompareDataRangeProd: "",
        startDate: "",
        endDate: "", // Диапазон, отображаемый пользователю // Для отображения диапазона дат


        fullName: "",
        brandName: "",

        search: "",
        snackbar: false,
        message: "",

        newAcc: {
        },
        account: {
        },
        selectedType: null,
        rolesTest: [
            { label: "CustomerApp", value: 1 },
            { label: "RestaurantApp", value: 2 },
            { label: "WebApp", value: 3 },
        ],

        columns: [
            { label: 'Имя гостя', field: 'name' },
            { label: "Номер телефона", field: "phoneNumber" },
            { label: 'Потрачено всего', field: 'spentAmount' },
            { label: 'Предоставлено скидок', field: 'percentSaved' },
            { label: 'Текущий уровень скидки', field: 'currentPercent' },
            { label: 'Следующий уровень', field: 'nextPercent' },
            { label: 'Следующий уровень через', field: 'toNextLevelAmount' },

        ],
        headers: [
            { text: "Имя гостя", align: "start", value: "name", },
            { text: "Номер телефона", value: "phoneNumber", width: "14%" },
            { text: "Потрачено всего", value: "spentAmount", width: "14%" },
            { text: "Предоставлено скидок", value: "percentSaved", width: "14%" },
            { text: "Текущий уровень скидки", value: "currentPercent", width: "14%" },
            { text: "Следующий уровень", value: "nextPercent", width: "14%" },
            { text: "Следующий уровень через", value: "toNextLevelAmount", width: "14%" },

        ],
        accounts: [],
        profiles: [],
        error: null,
        // currentPage: 1,
        // itemsPerPage: 10,
        // totalItems: 0,
        // totalPages: 0,
        currentAcc: "",
        showNoDataMessage: true,
        guestType: null,
        guestAvatarImage: "",
        currentLevelStart: '',
        currentLevelEnd: "",
        currentMoneySpend: "",
        nextLevel: "",
        // options: {
        //     page: 1, // текущая страница
        //     itemsPerPage: 10, // количество записей на страницу
        //     sortBy: [], // параметр сортировки
        //     sortDesc: [] // направление сортировки (по убыванию)
        // },

    }),

    created() {
        // this.initialize();
        // this.profileInfo();
        this.setWeeklyDateRange();

        // this.getGuestList();
        // this.getAllRestaurants();
        // this.restaurants.push(this.selectedRestaurant)
        console.log(this.restaurantsList);
        this.headers.forEach(header => {
            this.$set(this.sortOrder, header.value, 'default');
        });
        if (this.restaurantsList.length === 1) {
            this.selectedRestaurant = this.restaurantsList[0].restaurantId;
        }
    },
    computed: {
        ...mapState('bonusGroup', [
            'tableData',
            'currentPage',
            'itemsPerPage',
            'totalItems',
            'totalPages',
            'isLoadingGuest',
            'search',
            'sort',
            'bonusId',
            'dateFilter',
        ]),
        dateFilter: {
            get() {
                return this.$store.state.bonusGroup.dateFilter;
            }
        },
        bonusId: {
            get() {
                return this.$store.state.bonusGroup.bonusId;
            }
        },
        // currentPage() {
        // return this.$store.state.bonusGroup.currentPage; 
        // },
        // itemsPerPage() {
        // return this.$store.state.bonusGroup.itemsPerPage; // Количество элементов на страницу из Vuex
        // },
        // totalItems() {
        // return this.$store.state.bonusGroup.totalItems; // Общее количество элементов из Vuex
        // },
        // totalPages() {
        // return Math.ceil(this.totalItems / this.itemsPerPage); // Вычисление общего количества страниц
        // },
        isLoading: {
            get() {
                return this.$store.state.bonusGroup.isLoading;
            }
        },
        isLoadingGuest: {
            get() {
                return this.$store.state.bonusGroup.isLoadingGuest;
            }
        },
        search: {
            get() {
                return this.$store.state.bonusGroup.search; // Получаем текущее значение из Vuex
            },
            set(value) {
                this.$store.commit('bonusGroup/SET_SEARCH_QUERY', value);
                this.$store.commit('bonusGroup/SET_CURRENT_PAGE', 1); // Сбрасываем страницу на 
                // Обновляем значение в Vuex
                this.fetchTableData();
            },
        },
        options: {
            get() {
                return {
                    page: this.$store.state.bonusGroup.currentPage,
                    itemsPerPage: this.$store.state.bonusGroup.itemsPerPage,
                    sortBy: this.$store.state.bonusGroup.sort?.property ? [this.$store.state.bonusGroup.sort.property] : [],
                    sortDesc: this.$store.state.bonusGroup.sort?.isDescending ? [true] : [],
                };
            },
            set(newOptions) {
                this.$store.commit('bonusGroup/SET_CURRENT_PAGE', newOptions.page);
                this.$store.commit('bonusGroup/SET_ITEMS_PER_PAGE', newOptions.itemsPerPage);

                if (newOptions.sortBy.length > 0) {
                    this.$store.commit('bonusGroup/SET_SORT', {
                        property: newOptions.sortBy[0],
                        isDescending: newOptions.sortDesc[0] || false,
                    });
                } else {
                    this.$store.commit('bonusGroup/SET_SORT', null);
                }
            },
        },
        processedBonuses() {
            return this.guest.dashboardContactBonus.map(bonus => {
                const matchingData = this.findMatchingDiscount(bonus.dashboardBonusLines, bonus.currentPercent);
                return {
                    ...bonus,
                    matchingDiscount: matchingData.discountPercent,
                    spentAmountFrom: matchingData.spentAmountFrom,
                    spentAmountTo: matchingData.spentAmountTo,
                    isMaxDiscount: matchingData.isMaxDiscount,
                    nextLevelPercent: matchingData.nextLevelPercent, // Добавляем следующий discountPercent
                    previousSpentAmountFrom: matchingData.previousSpentAmountFrom, // Добавляем предыдущие значения
                    previousSpentAmountTo: matchingData.previousSpentAmountTo,
                };
            });
        },
        filteredGuests() {
            // Фильтруем данные внутри таблицы в зависимости от выбранного типа
            if (this.selectedType) {
                console.log(this.selectedType);
                return this.dashboardData.items
                    .filter
                    // (item) => item.rolesTest === this.selectedType
                    ();
            } else {
                return this.dashboardData.items;
            }
        },
        totalItems: {
            get() {
                console.log('Получение totalItems:', this.$store.state.bonusGroup.totalItems);
                return this.$store.state.bonusGroup.totalItems;
            },
            set(value) {
                console.log('Обновление totalItems:', value);
                this.$store.commit('bonusGroup/SET_TOTAL_ITEMS', value);
            }
        },
        totalPages: {
            get() {
                console.log('Получение totalPages:', this.$store.state.bonusGroup.totalPages);
                return this.$store.state.bonusGroup.totalPages;
            },
            set(value) {
                console.log('Обновление totalPages:', value);
                this.$store.commit('bonusGroup/SET_TOTAL_PAGES', value);
            }
        },
        footerProps() {
            return {
                itemsPerPageOptions: [5, 10, 25, 50], // Опции количества элементов на странице
                itemsPerPage: this.itemsPerPage,
                currentPage: this.currentPage,
                totalItems: this.totalItems,
            };
        },
        ...mapGetters({
            user: "user",
        }),
        textareaPlaceholder() {
            return this.isPlaceholderHidden ? "" : "---";
        },
        formattedLevelStart() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelStart).toLocaleString();

        },
        formattedLevelEnd() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelEnd).toLocaleString();

        },
        formattedMoneySpend() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentMoneySpend).toLocaleString();

        },

    },

    mounted() {
        console.log('Received dashboardData in PagedList:', this.dashboardData); // Лог данных
        // this.getDashboardInformation();
        this.setInitialDateRange();
        console.log('Дочерний компонент смонтирован. totalItems:', this.totalItems);
        // this.guestData;
    },


    methods: {
        ...mapActions('bonusGroup', ['fetchTableData']),
        fetchTableData() {
            // Вызов действия для получения данных таблицы
            this.$store.dispatch("bonusGroup/fetchTableData");
        },
        formatData(data) {
            return data.map((item) => ({
                ...item,
                name: this.formatName(item.name),
                spentAmount: item.spentAmount
                    ? `${Math.floor(Number(item.spentAmount)).toLocaleString('ru-RU')} ₸`
                    : '0 ₸',
                percentSaved: item.percentSaved
                    ? `${Math.floor(Number(item.percentSaved)).toLocaleString('ru-RU')} ₸`
                    : '0 ₸',

                currentPercent: item.currentPercent
                    ? `${Math.floor(Number(item.currentPercent)).toLocaleString('ru-RU')} %`
                    : '0 %',
                nextPercent: item.nextPercent
                    ? `${Math.floor(Number(item.nextPercent)).toLocaleString('ru-RU')} %`
                    : '0 %',
                toNextLevelAmount: item.toNextLevelAmount
                    ? `${Math.floor(Number(item.toNextLevelAmount)).toLocaleString('ru-RU')} ₸`
                    : '0 ₸',


            }));
        },


        onRestaurantChange(item) {
            // Здесь вы обновляете название ресторана
            const selectedRestaurantObj = this.restaurantsList.find(restaurant => restaurant.restaurantId === this.selectedRestaurant);
            if (selectedRestaurantObj) {
                this.selectedRestaurantName = selectedRestaurantObj.restaurant.name;
            } else {
                this.selectedRestaurantName = null;  // Это можно для ясности оставить, хотя оно и так будет null по умолчанию
            }
            this.currentPage = 1;
            this.guestType = null;
            this.activeBlock = 0;
            this.fetchDashboardData();
            this.getGuestList();

        },
        // Функция для выгрузки в Excel
        getExcelFileName() {
            if (this.guestType === 1) {
                return `Новые Гости-${this.formattedDateRangeProd}-${this.selectedRestaurantName || 'все рестораны'}`;
            }
            else {
                return `Гости-${this.selectedRestaurantName || 'все рестораны'}`;
            }
        },
        // Если название ресторана пустое, пишем "все рестораны"    },
        async getGuestListDownload() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            this.isLoadingGuest = true;
            console.log('Loading data...');

            try {
                const guestResponse = await this.$http.put('/api/Dashboard/GetClientsPagedList', {
                    pageNumber: 1,
                    pageSize: this.totalItems,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        restaurantId: this.selectedRestaurant,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        dataType: this.dataTypePeriod,
                        clientType: this.guestType
                    }
                });

                if (guestResponse.status === 204 || !guestResponse.data) {
                    console.warn('No guest data for the selected period.');
                } else {
                    console.log('Data received from API:', guestResponse.data);
                    this.downLoadOrderData.items = guestResponse.data.items;
                    console.log('Data written to downLoadOrderData.items:', this.downLoadOrderData.items);

                    this.isLoadingGuest = false;
                    this.showNoDataMessage = this.totalItems === 0;

                    // Устанавливаем флаг, что данные загружены
                    this.isDataLoaded = true;
                }
            } catch (error) {
                console.error('Error fetching guest information:', error);
                this.isLoadingGuest = false;
            }
        },

        findMatchingDiscount(dashboardBonusLines, currentPercent) {
            if (!dashboardBonusLines || !Array.isArray(dashboardBonusLines)) {
                return { discountPercent: 'Нет данных', spentAmountFrom: null, spentAmountTo: null, isMaxDiscount: false, nextLevelPercent: null, previousSpentAmountFrom: null, previousSpentAmountTo: null };
            }

            const matchingBonus = dashboardBonusLines.find(bonus => bonus.discountPercent === currentPercent);

            // Найдем следующий discountPercent
            const nextBonus = dashboardBonusLines
                .filter(bonus => bonus.discountPercent > currentPercent)
                .sort((a, b) => a.discountPercent - b.discountPercent)[0];

            // Найдем предыдущий discountPercent
            const previousBonus = dashboardBonusLines
                .filter(bonus => bonus.discountPercent < currentPercent)
                .sort((a, b) => b.discountPercent - a.discountPercent)[0];

            return {
                discountPercent: matchingBonus ? matchingBonus.discountPercent : currentPercent,
                spentAmountFrom: matchingBonus ? matchingBonus.spentAmountFrom : null,
                spentAmountTo: matchingBonus ? matchingBonus.spentAmountTo : null,
                isMaxDiscount: dashboardBonusLines.every(bonus => bonus.discountPercent <= currentPercent), // Проверка на максимальную скидку
                nextLevelPercent: nextBonus ? nextBonus.discountPercent : null, // Сохраняем следующий discountPercent
                previousSpentAmountFrom: previousBonus ? previousBonus.spentAmountFrom : null, // Сохраняем предыдущие значения
                previousSpentAmountTo: previousBonus ? previousBonus.spentAmountTo : null,
            };
        },
        // Расчет процента прогресса от 0 до currentLevelEnd
        progressValue(guestLevel) {
            const end = parseInt(guestLevel.spentAmountTo);
            const spent = parseInt(guestLevel.spentAmount);

            // Вычисляем процент прогресса относительно всего диапазона (0 до currentLevelEnd)
            const percent = (spent / end) * 100;

            // Убедитесь, что значение находится в пределах 0-100
            return Math.max(0, Math.min(100, percent));
        },
        // Вычисляем оставшуюся сумму до конца уровня
        remainingAmount(guestLevel) {
            const end = parseInt(guestLevel.spentAmountTo);
            const spent = parseInt(guestLevel.spentAmount);
            const remaining = end - spent; // Остаток до currentLevelEnd
            return remaining.toLocaleString(); // Преобразуем в строку с разделителями
        },
        formatPhoneNumber(phoneNumber) {
            if (!phoneNumber) return '';
            const rawPhone = phoneNumber.replace(/\D/g, ''); // Убираем все символы, кроме цифр
            return `+7 ${rawPhone.substring(1, 4)} ${rawPhone.substring(4, 7)} ${rawPhone.substring(7, 9)} ${rawPhone.substring(9, 11)}`;
        },
        formatDate(dateString) {
            if (!dateString) {
                return 'Не указана'; // или любое значение по умолчанию
            }
            const date = new Date(dateString);
            // Форматируем дату в нужный формат (например, "дд.мм")
            return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
        },
        formatName(name) {
            return name || 'Не указано';

        },
        handleSort(column) {
            // Проверяем текущее состояние сортировки для колонки и переключаем его
            console.log(this.sortOrder[column])
            if (this.sortOrder[column] === 'default') {
                this.$set(this.sortOrder, column, 'ascending');
                this.sortData(column, 'ascending');
            } else if (this.sortOrder[column] === 'ascending') {
                this.$set(this.sortOrder, column, 'descending');
                this.sortData(column, 'descending');
            } else {
                this.$set(this.sortOrder, column, 'default');
                this.resetSort(column);
            }
        },
        sortData(column, order) {
            // Логика сортировки данных для конкретного столбца
            console.log(`Сортировка ${column}: ${order}`);
        },
        resetSort(column) {
            // Логика сброса сортировки
            console.log(`Сброс сортировки для ${column}`);
        },
        // sortData(value) {
        //   this.sortDesc = this.sortBy === value ? !this.sortDesc : false; // Переключаем направление сортировки
        //   this.sortBy = value;

        //   this.items.sort((a, b) => {
        //     if (this.sortDesc) {
        //       return a[value] < b[value] ? 1 : -1;
        //     } else {
        //       return a[value] > b[value] ? 1 : -1;
        //     }
        //   });
        // },
        // getAllRestaurants() {
        //     let allRest = { restaurantId: null, restaurant: { name: "Все рестораны" } };
        //     console.log(this.user.roles); // Для отладки

        //     // Получаем рестораны из ролей пользователя
        //     this.restaurantsList = [...this.user.roles];

        //     // Проверяем, если ресторанов больше одного, добавляем "Все рестораны" в начало списка
        //     if (this.restaurantsList.length > 1) {
        //         this.restaurantsList.unshift(allRest);
        //     }

        //     console.log(this.restaurantsList);
        // },

        getCompareDashboard() {
            this.formattedDateRangeProd = this.formattedDateRange;
            this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
            this.dialog = false;
            this.guestType = null;
            this.activeBlock = 0;
            this.currentPage = 1;
            this.fetchDashboardData();
            //this.getDashboardInformation();
            this.getGuestList();
        },

        setWeeklyDateRange() {
            // Устанавливаем диапазон для текущей недели
            const today = moment(); // Текущая дата
            this.dataTypePeriod = 0;
            this.startDate = today.startOf('isoWeek').format("YYYY-MM-DD");
            this.endDate = today.endOf('isoWeek').format("YYYY-MM-DD");
            console.log(this.startDate);
            console.log(this.endDate);
            this.formattedDateRange = `${this.startDate} - ${this.endDate}`;
            this.formattedDateRangeProd = this.formattedDateRange;
            //   this.startDate = this.startDate.toISOString();
            //   this.endDate = this.endDate.toISOString();
        },


        getDashboardInformation() {
            this.isLoading = true;

            // Первый запрос - получение информации о клиентах
            this.$http.get('/api/Dashboard/GetClientsStatistics', {
                params: {
                    restaurantId: this.selectedRestaurant,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    dataType: this.dataTypePeriod
                }
            })
                .then((response) => {
                    if (response.status === 204 || !response.data) {
                        this.noDataMessage = 'За выбранный период данных нет.';
                        this.dashboardInfo = {};
                    } else {
                        this.dashboardInfo = response.data;
                        this.noDataMessage = '';
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.error('Error fetching dashboard information:', error);
                });
        },
        getGuestList() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            this.isLoadingGuest = true
            this.$http.put('/api/Dashboard/GetClientsPagedList', {
                pageNumber: this.currentPage,
                pageSize: this.itemsPerPage,
                // filters: Если потребуется, можно вернуть фильтры
                filters: [
                    {
                        property: "PhoneNumber",
                        operator: "contains",
                        value: this.search.replace(/\s+/g, ''),
                    },

                ],
                sorting: sortBy.length
                    ? {
                        property: sortBy[0], // поле для сортировки
                        isDescending: sortDesc[0] || false // направление сортировки
                    }
                    : null
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    restaurantId: this.selectedRestaurant,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    dataType: this.dataTypePeriod,
                    clientType: this.guestType
                }
            })
                .then((guestResponse) => {
                    // Обрабатываем ответ второго запроса
                    if (guestResponse.status === 204 || !guestResponse.data) {
                        console.warn('No guest data for the selected period.');
                    } else {
                        this.guestData = guestResponse.data;
                        this.totalPages = guestResponse.data.totalPages; // Общее количество страниц
                        this.totalItems = guestResponse.data.totalCount;
                        if (this.totalItems === 0) {
                            this.showNoDataMessage = true;
                            this.isLoadingGuest = false;
                        } else {
                            this.showNoDataMessage = false;
                            this.isLoadingGuest = false;
                        } // Общее количество элементов
                    }
                })
                .catch((error) => {
                    console.error('Error fetching guest information:', error);
                });
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.$store.commit('bonusGroup/SET_CURRENT_PAGE', this.currentPage + 1);
                this.fetchTableData();
                console.log("текущая странница:" + this.currentPage);
            }
        },
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.$store.commit('bonusGroup/SET_CURRENT_PAGE', this.currentPage - 1);
                this.fetchTableData();
                console.log("пред странница:" + this.currentPage);
            }
        },
        changeItemsPerPage(newItemsPerPage) {
            this.$store.commit('bonusGroup/SET_ITEMS_PER_PAGE', newItemsPerPage);
            this.$store.commit('bonusGroup/SET_CURRENT_PAGE', 1);
            this.fetchTableData();
        },
        setGuestType(type) {
            this.guestType = type;
            this.activeBlock = type;
            // this.getGuestList();

        },
        filtersItems() {
            this.currentPage = 1;
            this.guestType = null;
            this.activeBlock = 0;
            this.getDashboardInformation();
            // this.getGuestList();
        },
        getGuest(item) {
            this.editedIndex = this.tableData.items.indexOf(item);
            this.guestDialog = true;
            this.editedItem = Object.assign({}, item);
            this.$store.commit('bonusGroup/SET_LOADING_GUEST', true);
            ;
            this.$http
                .get(`/api/BonusGroupClient/${item.contactId}`, {
                    params: {
                        bonusGroupId: this.bonusId,
                        startDate: this.dateFilter.from,
                        endDate: this.dateFilter.to,
                    }
                })
                .then((response) => {
                    this.guest = response.data;
                    if (this.guest.imageDto) {
                        const randomParam = Math.random();
                        this.guestAvatarImage =
                            process.env.VUE_APP_IMAGE_API_HOST + this.guest.imageDto.imagePath + `?random=${randomParam}`;
                    }
                    else {
                        this.guestAvatarImage = null;
                    };

                    console.log(this.guestAvatarImage)
                    this.$store.commit('bonusGroup/SET_LOADING_GUEST', false);
                })
                .catch((error) => {
                    alert(error);
                });
        },
        closeGuestDialog() {
            this.guestDialog = false;
            this.guestAvatarImage = "";
            this.guest = {};
        },
        updateOptions(newOptions) {
            this.options = newOptions; // Передача в setter options
        },
    },

    watch: {
        downLoadOrderData(newData) {
            if (newData && newData.items && newData.items.length > 0) {
                console.log('Data updated and ready for Excel:', newData.items);
            }
        },
        // totalItems(newVal) {
        //     console.log('Обновлено количество элементов:', newVal);
        // },
        search: debounce(function () {
            this.$store.commit('bonusGroup/SET_SEARCH_QUERY', this.search);
            this.$store.commit('bonusGroup/SET_CURRENT_PAGE', 1);
            this.fetchTableData();
        }, 500),
        // options: {
        //     handler() {
        //         this.getGuestList(); // вызов API при изменении опций
        //     },
        //     deep: true
        // }
    },



}

</script>
<style scoped>
.input-container {
    display: flex;
    align-items: center;
    gap: 150px;
    margin-bottom: 10px;
}

.table-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 17px;
}

.no-address-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 167px;
    font-size: 16px;
    color: #333;
    font-family: Inter;
    margin-bottom: 0px;

}

.price-remains-bold {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    color: #323c4d;
}

.loyality-container {
    overflow-y: auto;
    overscroll-behavior: none;
    padding-right: 10px
}

.rest-logo {
    margin-right: 11px;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}

.rest-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: #333;
    margin-bottom: 0px;
}

.rest-adress {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #98a2b2;
    margin-bottom: 0px;
}

.restaurant-item {

    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item:first-child {

    /* padding-left: 10px; */
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item.active {
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgb(152, 162, 178);
}

.block.active {
    background: rgba(212, 212, 225, 0.35);
    /* измените на нужный цвет */

    border: 1px solid var(--obvodka);
    /* например, рамка вокруг активного блока */
}

.block:not(.active):hover {
    box-shadow: 0 0 10px 0 rgba(137, 137, 137, 0.25);
    background: white;
}

.error-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
}

.price-remains-bold {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    color: #323c4d;
}

.error-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.guest-grid .v-data-table-header th div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cguest-grid .v-data-table-header th div small {
    font-size: 12px;
    color: gray;
}

.guest-grid th {
    white-space: nowrap;
}

.guest-grid th div {
    font-weight: bold;
}

.guest-grid th small {
    display: block;
    font-size: 12px;
    color: grey;
}

.blocks-img {
    width: 1.35416666667vw;
    height: 2.68518518519vh;
}

.block-date {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    color: #606c80;
    margin-bottom: 0px;
}

.neutral-arrows {
    color: #98a2b2;

}

.percent-text-gray {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-top: -11px;
    line-height: 1;
    width: 37px;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    line-height: 1;
    width: 37px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.block-title-number {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.875vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.block-numbers {
    display: flex;
    align-items: center;
}

.blocks-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.block-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.25vw;
    color: #606c80;
    margin-bottom: 0px;
    text-wrap: nowrap;
}

.blocks-container {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 22px;
    /* Отступ между блоками */
    /* justify-content: center; */
}

.block {
    /* width: 312px;
  height: 136px;
  padding: 11px 18px 8px 20px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer; */
    width: 312px;
    /* height: 136px; */
    padding: 11px 18px 8px 20px;
    background-color: white;
    /* color: white; */
    /* display: flex; */
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .theme--light.v-sheet {
    background-color: transparent;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
}


.calendar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.error-container {
    max-width: 50%;
    /* Задаем максимальную ширину контейнера */
    margin: 0 auto;
    margin-top: 20px;
    /* margin: 0px 20px; */
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-exel {
    margin-left: 10px;
}

.btn-exel {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: #323c4d;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    display: flex;
    align-items: center;

}

.btn-exel-callendar {
    border-radius: 5px !important;
    padding: 5px 18px !important;
    color: white !important;
    background: #323c4d !important;
    height: 36px !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 0.78125vw !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

.custom-text-field {
    background-color: white;
    margin-bottom: 100px;
    /* Белый фон */
}

.date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 12px;
}

#date-picker-body {
    background: #ffffff;
}

.title {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.25vw !important;
    color: rgb(50, 60, 77);
    margin-right: 10px;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.tabs {
    padding: 0 30px 0px 0px;
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
    border-radius: 4px;
    /* Радиус скругления ползунка */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет трека */
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-autocomplete__content.v-menu__content .v-select-list {
    border-radius: 0 0 4px 4px;
    background: green;
}



::v-deep .v-list-item:first-child {
    border-bottom: none;
}

::v-deep .v-list .v-list-item--active {
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* Толщина, стиль и цвет левой границы */
}

::v-deep .v-autocomplete__content input {
    border: none;
    /* Убираем рамку */
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

/* Стили для скрытия рамки при наведении */
::v-deep .v-autocomplete__content .v-list-item:hover {
    border: none;
}

/* Применяем ваши стили к полю ввода */
::v-deep .v-autocomplete__content input {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    width: 100%;
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content {
    max-height: none;
}

::v-deep .v-autocomplete__content>.v-menu__content>.v-select-list {
    color: pink !important;
    background-color: #00b800 !important;
    margin: 100px;
}

#accounts>.container {
    border-radius: 8px;
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
    color: #fff;
    /* White text color */
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
    background-color: #357a38;
    /* Darker green background on hover */
}

.role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
}

.profile-menu-item:hover {
    /* Здесь вы можете задать стили для hover-эффекта */
    background-color: #f0f0f0;
    /* Пример: изменение цвета фона при наведении */
    color: #333;
    /* Пример: изменение цвета текста при наведении */
    cursor: pointer;
    /* Пример: изменение курсора при наведении */
    /* Другие стили по вашему усмотрению */
}

.border-input {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
    padding: 6px;
}

.border-white {
    border-radius: 8px;
    border: 1px solid var(--02, white);
    background: var(--white, #fff);
    padding: 6px;
    cursor: pointer;
    width: 100%;
}

.divider-modal {
    background: #e0e0e0;
    height: 1px;
    width: 100%;
    /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
}

.input-menu {
    position: absolute;
    bottom: 32%;
    left: 30.7%;
    width: 347px;
    height: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
    transition: bottom 0.3s;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 100;
    /* right: -15px; */
}

.select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    /* margin-left: 15px; */
}

.option-btn {
    margin-bottom: 0px;
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

/* .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 46px;
    align-items: center;
    margin-right: 20px;
  } */
.red-dot-margin-2 {
    margin-left: -17px;
}

.red-dot-margin-1 {
    text-align: center;
    margin-left: -17px;
}

.btn-contaiener {
    display: flex;
    justify-content: space-evenly;
}

.cancel-btn {
    color: var(--02, #848484);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--02, #848484);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-right: 21px;
}

.btn-download {
    display: flex;
    align-items: center;
    gap: 5px;
}

.delete-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.snackbar-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    margin-right: auto;
    padding: 14px 16px;
    text-align: initial;
    cursor: pointer;

}

.save-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #00b800);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
}

.del-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #b80900);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-left: 30px;
}

.text-limit {
    width: 30ch;
    /* Отображение примерно 10 символов */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--Gray-1, #333);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
}

.horizontal-line {
    /* Ширина div'а будет равна 100% от родительского контейнера */
    height: 1px;
    /* Толщина линии */
    background: #848484;
    /* Цвет линии (в данном случае черный) */
}

.custom-select {
    position: relative;
    width: 350px;
    cursor: pointer;
    /* margin-left: 9px; */
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
}

.select-arrow {
    position: absolute;
    top: 40%;
    right: 30px;
    /* transform: translateY(-50%); */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    /* Цвет стрелочки */
}

.col-description,
:focus {
    outline: none;
    /* Убираем внешний контур (outline) при фокусе */
    border: none;
    /* Убираем границу (border) при фокусе */
    box-shadow: none;
    /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
    color: #e41313;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.col-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    margin-bottom: 0px;
}

.col-paddings-r-l {
    padding-right: 0px;
    padding-left: 0px;
}

.col-content {
    display: flex;
    align-items: flex-start;
}

.role-margin {
    margin-right: 37px;
}

.role-margin-1 {
    margin-right: 30px;
}

.col-content-2 {
    display: flex;
    align-items: center;
    width: fit-content;
}

.col-description {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
    word-wrap: break-word;
    resize: none;
    height: 30px;
    /* min-height: 20px; */
    width: 100%;
    /* margin-left: 18px; */
}

::v-deep .v-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: none;
}

.modal-title {
    color: var(--gray-1, #333);
    font-family: Inter !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.page-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.vertical-line {
    width: 1px;
    height: 42px;
    background: #e0e0e0;
    margin-left: 31px;
}

.vertical-line-1 {
    width: 1px;
    height: 50px;
    background: #e0e0e0;
}

.add-btn .add-text {
    color: #Fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 12px;
    transition: color 0.3s ease;
}

.add-btn:hover .add-text {
    /* Цвет текста при наведении */
    color: white;
    /* Замените "red" на желаемый цвет текста */
    /* font-weight: 500;
    font-size: 17px; */
}

.add-btn {
    display: flex;
    align-items: center;
    padding: 5px 18px 5px 18px;
    margin-left: 37px;
    cursor: pointer;
    border-radius: 5px;
    background: rgb(50, 60, 77);
    height: 36px;
}

.top-panel {
    /* height: 50px; */
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.search-container {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-pluss-btn {
    margin-left: 10px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-notfound {
    /* display: flex; */
    margin: 0 auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.search-component {
    /* max-width: 430px;
    width: 100%; */
    height: 45px;
    align-items: center;
    display: inline-block;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    gap: 157px;
    margin-top: 12px;
    width: 100%;
    max-width: 425px;
}

.search-component__input {
    width: 100%;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    max-width: 425px;
    outline: unset;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    max-width: 425px;
}

.chart-name-container {
    width: fit-content;
}

.search-component__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* отступ от правого края */
    transform: translateY(-50%);
    pointer-events: none;
    /* чтобы клики не перехватывались SVG */
}

#staff-add-button {
    border-radius: 8px;
    background-color: #333;
    color: var(--white, #fff);
    gap: 12px;
    padding: 10px 56px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 45px;
}

::v-deep .guest-grid tbody tr:nth-child(2n) {
    background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    color: var(--gray-1, #333) !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
    color: #606C80 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: 2px solid #606C80;
}

.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #98a2b2;
    /* Серый цвет */
    /* border-radius: 10px; */
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #323c4d;
    /* Красный цвет заполнения */
    width: 0;
    transition: width 0.3s ease;
}

.card-bonus {
    display: flex;
    flex-direction: column;
}

.card-bonus-text {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #98a2b2;
}

.bonus-points {
    font-family: Inter;
    font-size: 30.62px;
    font-weight: 700;
    /* line-height: 37.06px; */
    text-align: left;
    color: #323C4D;
    margin-bottom: 0px;
    margin-right: 5px;
}

.first-order-container {
    margin-top: 10px;
}

.register-container {
    margin-top: 14px;
    margin-bottom: 27px;
}

.birthday-container {
    margin-bottom: 21px;
}

.gender-container {
    margin-bottom: 15px;
}

.phone-container {
    margin-top: 12px;
    margin-bottom: 15px;
}

.small-icons {
    width: 20px;
    height: 20px;
}

.user-column {
    display: flex;
    flex-direction: column;
}

.avatar-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #606c80;
}






#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #606c80;
}

.location-containers {
    margin-bottom: 11px;
    display: flex;
    margin-top: 17px;
    flex-direction: column;


}

.location-container {

    overflow-y: auto;
    overscroll-behavior: none;


}

.location-info {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.location-adress {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #606C80;
    margin-bottom: 0px;
}

.location-name {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    color: #323C4D;
    margin-bottom: 0px;
}

.third-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 388px;
}

.order-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.order-info-text-second {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    /* text-align: right; */
    color: #323c4d;
    margin-bottom: 0px;
}

.order-info-text-second-2 {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    text-align: right;
    color: #323c4d;
    margin-bottom: 0px;
}

.order-info-containers {
    display: flex;
    justify-content: space-between;
}

.order-info-containers-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.order-info-text-green {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #00b800;
    margin-bottom: 0px;
    padding: 0 10px;

}

.order-info-container {
    margin-top: 24px;
}

.date-containers {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

.date-container-1 {
    max-width: 121px;
    width: 100%;
}

.date-container-2 {
    max-width: 115px;
    width: 100%;
    margin-right: 74px;
}


.date-order {
    font-family: inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.order-1 {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.divider-modal {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;

    /* Расширяем на всю ширину родительского контейнера */
}

.review-img {
    width: 22px;
    padding-right: 5.8px;
}

.review-container {
    display: flex;
    align-items: center;
}

.review-text {

    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    color: #606c80;

}

.review-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #606c80;
    margin-bottom: 0px;
    padding-right: 10px;
}

.title-name {
    font-family: Inter;
    font-weight: 500;
    font-size: 15pxem;
    color: #323c4d;
    margin-bottom: 0px;
    padding-left: 14px;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-card {
    padding: 10px 26px 38px;
    background-color: #F8F8F8 !important;
}

.card-username {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /* line-height: 19.36px; */
    text-align: center;
    margin-bottom: 0px;
    margin-top: 8px;

}

.card-item-second {
    background: #fff;
    max-width: 388px;
    width: 100%;
    border-radius: 5px;
    height: 683px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: 15px 13px;

}

.card-item {
    background: #fff;
    /* max-width: 247px; */
    width: 388px;
    border-radius: 5px;
    /* height: 620px; */
    display: flex;
    flex-direction: column;
    padding: 15px 25px 7px;

}

.card-item-3 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    /* height: 35.2777777778vh; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
}

.card-item-4 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    height: 231px;
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.card2-title {
    margin: 0;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

}



.card-cashback {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #98A2B2;
}

.bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price-circle {

    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #323C4D;

}

.price-circle-gray {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.price-line-gray {
    background-color: #98A2B2;

    width: 347px;
    height: 2px;
    margin-top: -8px;

}

.price-line-fill {
    width: 260px;
    height: 5px;
    background-color: #323C4D;
    border-radius: 4px;

    margin-top: -4px;
}

.price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.price-container p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #98A2B2;
}

.price-remains {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

    color: #98A2B2;
}



.card-label {
    color: #606C80;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 2px; */
    text-align: left;
    /* margin-top: 8px; */
    margin-bottom: 5px;

}

.card-text {
    color: #333333;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    margin-bottom: 0px;
}

.card-line {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;
}

.modal-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;

}

.modal-header {
    padding: 16px 5px 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    padding-top: 15px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
}

::v-deep .v-dialog {
    margin: 0px;
}

.cashback-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

    color: #323C4D;
}

.chart-container {
    position: relative;
}

.chart-circle-container {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;

    left: -12.5px;
}

.chart-circle-gray {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.chart-circle-container div {
    margin-bottom: 40px;
}

.cashback-line-gray {
    width: 3px;
    height: 280px;
    background-color: #9F9F9F;
}

.cashback-line-fill {
    /* position: relative; */
    /* top: 90px; */
    margin-top: -200px;
    width: 6px;

    margin-left: -1.5px;
    border-radius: 4px;
    background-color: #323C4D;
}

.cashback-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    width: 50%;
}

.cashback-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.cashback-info div {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    margin-left: 12.5px;
}

.cashback-info h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 5px;
}

.cashback-info p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin: 0;
    margin-left: 5px;

    color: #606C80;
}

.cashback-prices p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 0px; */
    text-align: left;
    margin-bottom: 72.5px;

    /* margin: 0; */
    color: #606C80;
}

#custom-toolbar ::v-deep .v-toolbar__content {
    padding-left: 0 !important;
    /* Убирает отступ слева */
}


.chart-circle-gray-container {}

.chart-circle-gray-container {}

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}

@media (max-width: 1600px) {
    .block {
        width: calc(50% - 11px);
        /* 50% ширины с учётом gap */
    }
}

@media (max-width: 1146px) {
    .block {
        width: calc(19.5% - 11px);
        /* 50% ширины с учётом gap */
    }

    .block-title {
        font-size: 1.1vw;
    }
}

@media (max-width: 768px) {
    .block {
        width: 100%;
        /* Полная ширина на мобильных устройствах */
    }
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
    color: pink !important;
    background-color: #00b800 !important;
}

::v-deep .v-autocomplete__content {
    background-color: black;
    /* Устанавливаем черный цвет для фона меню */
    color: white;
    /* Устанавливаем белый цвет для текста в меню */
}
</style>