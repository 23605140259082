<template>
    <div class="loyalty-container-main">
        <div class="blocks-container">
            <div class="block-row">
                <div class="block">
                    <div class="blocks-flex">
                        <p class="block-title">Всего участников</p>
                        <img class="blocks-img" src="@/assets/img/total-guest.svg" alt="">
                    </div>
                    <div class="block-numbers">
                        <p class="block-title-number">{{ dashboardDataStatistic.clientAmount.amount }}</p>
                        <div class="">
                            <span class="percent-status" v-if="dashboardDataStatistic.clientAmount.percent === 0">
                                <span class="neutral-arrows">▲</span>
                                <span class="percent-text-gray">{{ dashboardDataStatistic.clientAmount.percent }}%</span>
                                <span class="neutral-arrows">▼</span>
                            </span>
                            <span class="percent-status-green" v-if="dashboardDataStatistic.clientAmount.percent > 0">
                                <span class="arrow-color-top">▲</span>
                                <span class="percent-text-green">{{ dashboardDataStatistic.clientAmount.percent.toFixed()
                                }}%</span>
                            </span>
                            <span class="percent-status percent-padding"
                                v-if="dashboardDataStatistic.clientAmount.percent < 0">
                                <span class="percent-text-red">{{ dashboardDataStatistic.clientAmount.percent.toFixed()
                                }}%</span>
                                <span class="arrow-color-bot"
                                    v-if="dashboardDataStatistic.clientAmount.percent < 0">▼</span>
                            </span>
                        </div>
                    </div>

                <div>
                    <!-- <p class="block-date">c {{ formatDate(dashboardDataStatistic.workStartDate) }}</p> -->
                    <p class="block-date">с {{ formatDate(dashboardDataStatistic.createdOn) }}</p>
                </div>

            </div>
            <div class="block">
                <div class="blocks-flex">
                    <p class="block-title">Потрачено всего</p>
                    <img class="blocks-img" src="@/assets/img/spent-amount.svg" alt="">
                </div>
                <div class="block-numbers">
                    <p class="block-title-number">{{ 
                               dashboardDataStatistic.totalSpentAmount.amount ?
                      Number( dashboardDataStatistic.totalSpentAmount.amount.toFixed()).toLocaleString('ru-RU')
                      : '0' }}</p>
                    <div class="">
                        <span class="percent-status" v-if="dashboardDataStatistic.totalSpentAmount.percent === 0">
                            <span class="neutral-arrows">▲</span>
                            <span class="percent-text-gray">{{ dashboardDataStatistic.totalSpentAmount.percent }}%</span>
                            <span class="neutral-arrows">▼</span>
                        </span>
                        <span class="percent-status-green" v-if="dashboardDataStatistic.totalSpentAmount.percent > 0">
                            <span class="arrow-color-top">▲</span>
                            <span class="percent-text-green">{{ dashboardDataStatistic.totalSpentAmount.percent.toFixed()
                            }}%</span>
                        </span>
                        <span class="percent-status percent-padding"
                            v-if="dashboardDataStatistic.totalSpentAmount.percent < 0">
                            <span class="percent-text-red">{{ dashboardDataStatistic.totalSpentAmount.percent.toFixed()
                            }}%</span>
                            <span class="arrow-color-bot" v-if="dashboardDataStatistic.totalSpentAmount.percent < 0">▼</span>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="block-date">с {{ formatDate(dashboardDataStatistic.createdOn) }}</p>
                </div>
            </div>
            </div>
            <div class="block-2">
                <div class="blocks-flex">
                    <p class="block-title">Предоставлено скидок</p>
                    <img class="blocks-img" src="@/assets/img/percent-icon.svg" alt="">
                </div>
                <div class="block-numbers">
                    <p class="block-title-number">{{ 
                        dashboardDataStatistic.totalSavedAmount.amount ?
                      Number( dashboardDataStatistic.totalSavedAmount.amount.toFixed()).toLocaleString('ru-RU')
                      : '0' }}</p>
                    <div class="">
                        <span class="percent-status" v-if="dashboardDataStatistic.totalSavedAmount.percent === 0">
                            <span class="neutral-arrows">▲</span>
                            <span class="percent-text-gray">{{ dashboardDataStatistic.totalSavedAmount.percent }}%</span>
                            <span class="neutral-arrows">▼</span>
                        </span>
                        <span class="percent-status-green" v-if="dashboardDataStatistic.totalSavedAmount.percent > 0">
                            <span class="arrow-color-top">▲</span>
                            <span class="percent-text-green">{{ dashboardDataStatistic.totalSavedAmount.percent.toFixed()
                            }}%</span>
                        </span>
                        <span class="percent-status percent-padding"
                            v-if="dashboardDataStatistic.totalSavedAmount.percent < 0">
                            <span class="percent-text-red">{{ dashboardDataStatistic.totalSavedAmount.percent.toFixed()
                            }}%</span>
                            <span class="arrow-color-bot"
                                v-if="dashboardDataStatistic.totalSavedAmount.percent < 0">▼</span>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="block-date">за текущий период</p>
                </div>
            </div>
            <!-- <div class="block">
                <div class="blocks-flex">
                    <p class="block-title">Потрачено всего</p>
                    <img class="blocks-img" src="@/assets/img/bonusgroup-small.svg" alt="">
                </div>
                <div class="block-numbers">
                    <p class="block-title-number">{{ dashboardDataStatistic.spentBonuses.amount.toFixed() }}</p>
                    <div class="">
                        <span class="percent-status" v-if="dashboardDataStatistic.spentBonuses.percent === 0">
                            <span class="neutral-arrows">▲</span>
                            <span class="percent-text-gray">{{ dashboardDataStatistic.spentBonuses.percent }}%</span>
                            <span class="neutral-arrows">▼</span>
                        </span>
                        <span class="percent-status-green" v-if="dashboardDataStatistic.spentBonuses.percent > 0">
                            <span class="arrow-color-top">▲</span>
                            <span class="percent-text-green">{{ dashboardDataStatistic.spentBonuses.percent.toFixed()
                            }}%</span>
                        </span>
                        <span class="percent-status percent-padding" v-if="dashboardDataStatistic.spentBonuses.percent < 0">
                            <span class="percent-text-red">{{ dashboardDataStatistic.spentBonuses.percent.toFixed()
                            }}%</span>
                            <span class="arrow-color-bot" v-if="dashboardDataStatistic.spentBonuses.percent < 0">▼</span>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="block-date">за текущий период</p>
                </div>
            </div> -->

        </div>
        <div class="loyalty-container"
            v-if="dashboardDataStatistic.levelsStatistics && !dashboardDataStatistic.levelsStatistics.every(item => item === null)">
            <div class="loyalty-levels-container">
                <p class="loyalty-top-title">Распределение по уровням программы лояльности</p>

                <div class="bar-chart-block">

                    <div class="stat-item" v-for="(product, index) in sortedLevelsStatistics" :key="index">

                        <div class="stat-container">
                            <p class="stat-title">
                                уровень {{ product.discountPercent }} %
                            </p>
                        </div>

                        <div class="stat-bars">
                            <div class="stat-bar-current">
                                <div class="bar"
                                    :style="{ width: calculateBarWidth(product.clientAmount) + '%', backgroundColor: getBarColor(index) }">
                                </div>
                                <span class="bar-amount">{{ product.clientAmount }}</span>
                            </div>
                        </div>

                        <div class="percent-hg" style="flex: 3">
                            <span class="percent-status" v-if="product.discountPercent === 0">
                                <span class="neutral-arrows">▲</span>
                                <span class="percent-text-gray">{{ product.discountPercent }}%</span>
                                <span class="neutral-arrows">▼</span>
                            </span>
                            <span class="percent-status-green" v-if="product.discountPercent > 0">
                                <span class="arrow-color-top">▲</span>
                                <span class="percent-text-green">{{ product.discountPercent.toFixed() }}%</span>
                            </span>
                            <span class="percent-status percent-padding" v-if="product.discountPercent < 0">
                                <span class="percent-text-red">{{ product.discountPercent.toFixed() }}%</span>
                                <span class="arrow-color-bot" v-if="product.discountPercent < 0">▼</span>
                            </span>
                        </div>

                    </div>

                </div>
            </div>
            <div class="loyalty-doughnut-container">
                <div class="doughnut-container">
                    <Doughnut class="don" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                        :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                        :width="width" :height="height" />
                    <div class="total-container-doughnut" v-if="centerText !== 0 && centerText !== null">
                        <p class="total-price-doughnut">{{ centerText.toLocaleString('ru-RU') }}</p>
                    </div>
                    <div class="levels-statistics-container"
                        v-if="dashboardDataStatistic.levelsStatistics && !dashboardDataStatistic.levelsStatistics.every(item => item === null)">

                        <div class="" v-for="(product, index) in sortedLevelsStatistics" :key="index">

                            <div class="levels-statistics-list">
                                <div :class="squareClasses[index]"></div>
                                <p class="levels-statistics-list-text">
                                    уровень {{ product.discountPercent }} %
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import moment from "moment";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { mapState, mapActions } from 'vuex';
import { Doughnut } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)
export default {
    components: {
        Doughnut,
    },
    directives: { mask },
    name: "progressiveDiscount",
    props: {
        chartId: {
            type: String,
            default: 'doughnut-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 222
        },
        height: {
            type: Number,
            default: 222
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        plugins: {
            type: Object,
            default: () => ({})
        },

        dashboardDataStatistic: {
            type: Object,  // Мы ожидаем объект
            default: () => ({})
        },
        dateRangeProd: {
            type: String, // Укажите тип переменной
            required: false, // Если не обязательно
        },

    },



    data: () => ({
        squareClasses: ['level-1', 'level-2', 'level-3', 'level-4'],

        barColors: ["#13414e", "#14bf91", "#006d5a", "#c3dcd5"],
        selectedRestaurant: null,
        imageHost: process.env.VUE_APP_IMAGE_API_HOST,
        progressValueFull: 100,
        restaurantsList: [],
        dashboardDataStatistic: {},
        sortOrder: {},
        showArrows: null,
        guestData: {},
        guest: {},
        noDataMessage: "",
        isLoading: true,
        isLoadingGuest: true,
        selectedDate: moment(new Date()).format('YYYY-MM-DD'),
        workStartDate: "", // Текущая выбранная дата
        dateType: "date", // Выбор по умолчанию: месяц
        dataTypePeriod: "",
        formattedDateRange: "",
        formattedDateRangeProd: "",
        formattedCompareDataRange: "",
        formattedCompareDataRangeProd: "",
        startDate: "",
        endDate: "", // Диапазон, отображаемый пользователю // Для отображения диапазона дат


        fullName: "",
        brandName: "",

        search: "",
        snackbar: false,
        message: "",

        newAcc: {
        },
        account: {
        },
        selectedType: null,
        rolesTest: [
            { label: "CustomerApp", value: 1 },
            { label: "RestaurantApp", value: 2 },
            { label: "WebApp", value: 3 },
        ],
        accounts: [],
        profiles: [],
        error: null,
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0,
        currentAcc: "",
        showNoDataMessage: false,
        guestType: null,
        guestAvatarImage: "",
        currentLevelStart: '',
        currentLevelEnd: "",
        currentMoneySpend: "",
        nextLevel: "",
        options: {
            page: 1, // текущая страница
            itemsPerPage: 10, // количество записей на страницу
            sortBy: [], // параметр сортировки
            sortDesc: [] // направление сортировки (по убыванию)
        },
        centerText: "",
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: "50%", // Уменьшите значение для уменьшения белого пространства
            plugins: {
                legend: {
                    display: false, // Отключаем легенду
                },
                tooltip: {
                    enabled: true,
                    bodyFont: {
                        family: "Inter", // Шрифт тултипа
                        size: 14, // Увеличиваем размер шрифта тултипа
                    },
                    padding: 5,
                },
                datalabels: {
                    color: "#fff",
                    font: {
                        family: "Inter",
                        weight: "700",
                        size: 14,
                    },
                },
            },
        },
        // totalClientAmount: null,
        chartData: {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                },
            ],
        },

    }),

    created() {

    },
    computed: {

        ...mapGetters({
            user: "user",
        }),
        textareaPlaceholder() {
            return this.isPlaceholderHidden ? "" : "---";
        },
        formattedLevelStart() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelStart).toLocaleString();

        },
        formattedLevelEnd() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelEnd).toLocaleString();

        },
        formattedMoneySpend() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentMoneySpend).toLocaleString();

        },
        totalClientAmount() {
            // Сумма всех clientAmount
            return this.dashboardDataStatistic.levelsStatistics.reduce(
                (total, item) => total + item.clientAmount,
                0
            );
        },
        sortedLevelsStatistics() {
            return this.dashboardDataStatistic.levelsStatistics
                .filter((product) => product !== null) // Исключаем null значения
                .sort((a, b) => a.discountPercent - b.discountPercent); // Сортируем по discountPercent
        }




    },

    mounted() {
        // Получаем сумму всех clientAmount
        const totalAmount = this.totalClientAmount;
        console.log('Total Amount:', totalAmount);  // Добавим вывод для отладки

        // Если сумма больше 0, присваиваем centerText, иначе присваиваем null
        this.centerText = totalAmount > 0 ? totalAmount : null;
    },


    methods: {
        ...mapActions('bonusGroup', ['fetchDashboardData']),


        formatPhoneNumber(phoneNumber) {
            if (!phoneNumber) return '';
            const rawPhone = phoneNumber.replace(/\D/g, ''); // Убираем все символы, кроме цифр
            return `+7 ${rawPhone.substring(1, 4)} ${rawPhone.substring(4, 7)} ${rawPhone.substring(7, 9)} ${rawPhone.substring(9, 11)}`;
        },
        formatDate(dateString) {
            if (!dateString) {
                return 'Не указана'; // или любое значение по умолчанию
            }
            const date = new Date(dateString);
            // Форматируем дату в нужный формат (например, "дд.мм")
            return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`
        },
        formatName(name) {
            return name || 'Не указано';

        },
        calculateBarWidth(clientAmount) {
            if (this.totalClientAmount === 0) return 0;
            const calculatedWidth = (clientAmount / this.totalClientAmount) * 100;
            return calculatedWidth === 0 ? 5 : calculatedWidth;
        },
        getBarColor(index) {
            // Возвращает цвет из массива barColors
            return this.barColors[index % this.barColors.length];
        },
        updateChartData(data) {
            const fixedColors = ["#13414e", "#006d5a", "#14bf91", "#c3dcd5"]; // Ваши фиксированные цвета
            []
            // Формирование данных для графика
            const labels = data.map((item) => `${item.discountPercent}%`);
            const dataset = data.map((item) => item.clientAmount);
            const backgroundColors = data.map((_, index) => fixedColors[index % fixedColors.length]);

            // Обновляем chartData
            this.chartData = {
                labels,
                datasets: [
                    {
                        data: dataset,
                        backgroundColor: backgroundColors,
                        borderWidth: 0
                    },
                ],
            };
        },





    },

    watch: {
        dashboardDataStatistic: {
            immediate: true,
            handler(newData) {
                this.updateChartData(newData.levelsStatistics || []);
            },
        },

        'dashboardDataStatistic.levelsStatistics': function (newValue) {
            this.centerText = this.totalClientAmount;  // Обновляем centerText при изменении данных
        },
    },



}

</script>
<style scoped>
.levels-statistics-list-text {
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.levels-statistics-list {
    display: flex;
    gap: 13.86px;
    align-items: center;
    margin-bottom: 20px;
}

.level-1 {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #13414e;
}

.level-2 {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #14bf91;
}

.level-3 {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #006d5a;
}

.level-4 {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: #c3dcd5;
}

.don {
    z-index: -1;
    position: relative;
}

.loyalty-levels-container {
    margin-right: 25px;
}

.levels-statistics-container {
    margin-left: 45px;
}

.horizontal-bar-fill {
    height: 100%;
    width: 0;
    /* Начальное значение для анимации */
    transition: width 1s ease;
    border-radius: 2px;
}

.bar-main {
    width: 100%;
}

.bar-count {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #606c80;
    margin-bottom: 0px;
}

.bars-container {
    display: flex;
    align-items: center;

}

.bar-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.total-price-doughnut {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.total-container-doughnut {
    position: absolute;
    top: 50%;
    /* Центрируем по вертикали */
    left: 28%;
    /* Центрируем по горизонтали */
    transform: translate(-50%, -50%);
    /* Сдвигаем на половину ширины и высоты */
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    text-align: center;
}

.bars-main-container {
    margin-left: 67px;
    width: 100%;
    max-width: 327px;
}

.percent-status-gray {
    margin-top: 5px;
}

.percent-status-red {
    margin-top: 5px;
}

.percent-text-gray {

    /* color: #c1c1c1;
    font-family: Inter;
    font-weight: 400;
    font-size: 13px; */
    padding-top: 3px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 11px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
}

.neutral-arrows-special {
    margin-top: -8px
}

.neutral-arrows-special-2 {
    margin-top: -8px
}

.neutral-arrows {
    color: #98a2b2;
    /* padding-bottom: 2px;
    padding-top: 2px; */
    height: 13px;
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    margin-top: 5px;
    line-height: 1;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
    /* line-height: 1; */
}

.container {
    display: flex;
}

.doughnut-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.cost-container-red {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.cost-container-green {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;
}

.cost-container-gray {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    transform: translate(-50%, -5%);
    text-align: center;

}

.percent-in-doughnut {
    line-height: 0px;
    font-size: 18px;
    color: red;

}

.cost-in-doughnut {

    font-family: Inter;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.73px;
    text-align: left;
    margin-bottom: 0px;
    z-index: 0;
    /* word-wrap: break-word; */
    /* Разрешить перенос слов */
    /* white-space: normal; */
    /* Позволяет тексту переноситься */
    /* max-width: 100%; */
    /* Ограничение ширины текста */
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
    /* margin-top: -7px; */
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

/* Horizontal price  */

.bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    height: 100%;
    gap: 10px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.horizontal-bar {
    background: #F0F0F0;
    max-width: 250px;
    height: 10px;
}

.horiontal-bar-fill {
    height: 10px;
    animation: horizontal-fill 1s;
    transition: all 1s ease-in-out;
}

@keyframes horizontal-fill {
    from {
        width: 0%;
    }

    to {
        width: inherit;
    }
}

.horizontal-bar-container {
    list-style-type: none;
}

.name-container {
    display: flex;
    align-items: center;
}

.name-container p {
    line-height: 0px;
    margin-top: 15px;
    margin-left: 10px;
}

.name-square-delivery {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #006D5A;
}

.name-square-inhouse {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #13414E;
}

.name-square-selfpick {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #14BF91;
}

.stat-container {
    max-width: 105px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.stat-bar-current {
    display: flex;
}

.bar-amount {
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    color: #606c80;
    margin-left: 13px;
}

.bar {
    /* background-color: #4caf50; */
    /* Цвет заполнения */
    height: 25px;
    transition: width 0.3s ease;
}



.stat-bars {
    /* flex: 2; 
    margin: 0 0 0 10px; */
    width: 100%;
    background-color: white;
    height: 25px;
    position: relative;
    overflow: hidden;
    margin-left: 25px;
}

.stat-title {
    margin-bottom: 0px;
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    color: #323c4d;
}

.stat-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    /* Верхняя рамка */
    /* border-bottom: 1px solid #e0e0e0; */
    /* Нижняя рамка */
    /* margin-bottom: 2px; */
    /* cursor: pointer; */
}

.stat-item:last-child {
    border-bottom: 1px solid #e0e0e0;
}

.bar-chart-block {
    background-color: white;
    padding: 0px 18px;
    border-radius: 5px;
    /* max-width: 377px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 207px;



}

.loyalty-container-main {
    display: flex;

}

.loyalty-top-title {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    padding: 0 16px;
}

.loyalty-container {
    max-width: 970px;
    width: 100%;
    display: flex;
    border-radius: 5px;
    background: white;
    margin-left: 25px;
    padding: 12px 16px 28px 12px;
}

.input-container {
    display: flex;
    align-items: center;
    gap: 150px;
    margin-bottom: 10px;
}

.table-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 17px;
}

.no-address-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 167px;
    font-size: 16px;
    color: #333;
    font-family: Inter;
    margin-bottom: 0px;

}

.loyality-container {
    overflow-y: auto;
    overscroll-behavior: none;
    padding-right: 10px
}

.rest-logo {
    margin-right: 11px;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}

.rest-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: #333;
    margin-bottom: 0px;
}

.rest-adress {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #98a2b2;
    margin-bottom: 0px;
}

.restaurant-item {

    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item:first-child {

    /* padding-left: 10px; */
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item.active {
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgb(152, 162, 178);
}

/* .block.active {
    background: rgba(212, 212, 225, 0.35);
    border: 1px solid var(--obvodka);
} */

/* .block:not(.active):hover {
    box-shadow: 0 0 10px 0 rgba(137, 137, 137, 0.25);
    background: white;
} */

.error-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
}

.error-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.guest-grid .v-data-table-header th div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cguest-grid .v-data-table-header th div small {
    font-size: 12px;
    color: gray;
}

.guest-grid th {
    white-space: nowrap;
}

.guest-grid th div {
    font-weight: bold;
}

.guest-grid th small {
    display: block;
    font-size: 12px;
    color: grey;
}

.blocks-img {
    width: 1.35416666667vw;
    height: 2.68518518519vh;
}

.block-date {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    color: #606c80;
    margin-bottom: 0px;
}

.neutral-arrows {
    color: #98a2b2;

}

.percent-text-gray {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.5729vw;
    color: #323c4d;
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.arrow-color-top {
    color: green;
    /* width: 16px;
    height: 13px; */
    /* padding-top: 2px; */
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-top: -11px;
    line-height: 1;
    width: 37px;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    line-height: 1;
    width: 37px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.572vw;
}

.block-title-number {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.875vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.block-numbers {
    display: flex;
    align-items: center;
}

.blocks-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 12px; */
}

.block-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.25vw;
    color: #606c80;
    margin-bottom: 0px;
    text-wrap: nowrap;
}

.blocks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    max-width: calc(2 * 312px + 22px);
    width: 100%;
    /* Ограничение ширины контейнера */
    /* Отступ между блоками */
    /* justify-content: center; */
}

.block-row {
    display: flex;
    flex-direction: row;
    gap: 22px;
    max-width: calc(2 * 312px + 22px);
    width: 100%;
}

.block {
    max-width: 312px;
    padding: 11px 18px 8px 20px;
    background-color: white;
    border-radius: 8px;
    padding: 11px 18px 8px 20px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
    /* max-width: calc(50% - 11px); */
    /* Делит ширину на два блока с учетом gap */

}

.block-2 {
    max-width: 355px;
    padding: 11px 18px 8px 20px;
    background-color: white;
    border-radius: 8px;
    width: 100%;
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .theme--light.v-sheet {
    background-color: transparent;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
}


.calendar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.error-container {
    max-width: 50%;
    /* Задаем максимальную ширину контейнера */
    margin: 0 auto;
    margin-top: 20px;
    /* margin: 0px 20px; */
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-exel {
    margin-left: 10px;
}

.btn-exel {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: #323c4d;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    display: flex;
    align-items: center;

}

.btn-exel-callendar {
    border-radius: 5px !important;
    padding: 5px 18px !important;
    color: white !important;
    background: #323c4d !important;
    height: 36px !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 0.78125vw !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;

}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

.custom-text-field {
    background-color: white;
    margin-bottom: 100px;
    /* Белый фон */
}

.date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 12px;
}

#date-picker-body {
    background: #ffffff;
}

.title {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.25vw !important;
    color: rgb(50, 60, 77);
    margin-right: 10px;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.tabs {
    padding: 0 30px 0px 0px;
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
    border-radius: 4px;
    /* Радиус скругления ползунка */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет трека */
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-autocomplete__content.v-menu__content .v-select-list {
    border-radius: 0 0 4px 4px;
    background: green;
}



::v-deep .v-list-item:first-child {
    border-bottom: none;
}

::v-deep .v-list .v-list-item--active {
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* Толщина, стиль и цвет левой границы */
}

::v-deep .v-autocomplete__content input {
    border: none;
    /* Убираем рамку */
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

/* Стили для скрытия рамки при наведении */
::v-deep .v-autocomplete__content .v-list-item:hover {
    border: none;
}

/* Применяем ваши стили к полю ввода */
::v-deep .v-autocomplete__content input {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    width: 100%;
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content {
    max-height: none;
}

::v-deep .v-autocomplete__content>.v-menu__content>.v-select-list {
    color: pink !important;
    background-color: #00b800 !important;
    margin: 100px;
}

#accounts>.container {
    border-radius: 8px;
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
    color: #fff;
    /* White text color */
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
    background-color: #357a38;
    /* Darker green background on hover */
}

.role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
}

.profile-menu-item:hover {
    /* Здесь вы можете задать стили для hover-эффекта */
    background-color: #f0f0f0;
    /* Пример: изменение цвета фона при наведении */
    color: #333;
    /* Пример: изменение цвета текста при наведении */
    cursor: pointer;
    /* Пример: изменение курсора при наведении */
    /* Другие стили по вашему усмотрению */
}

.border-input {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
    padding: 6px;
}

.border-white {
    border-radius: 8px;
    border: 1px solid var(--02, white);
    background: var(--white, #fff);
    padding: 6px;
    cursor: pointer;
    width: 100%;
}

.divider-modal {
    background: #e0e0e0;
    height: 1px;
    width: 100%;
    /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
}

.input-menu {
    position: absolute;
    bottom: 32%;
    left: 30.7%;
    width: 347px;
    height: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
    transition: bottom 0.3s;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 100;
    /* right: -15px; */
}

.select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    /* margin-left: 15px; */
}

.option-btn {
    margin-bottom: 0px;
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

/* .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 46px;
    align-items: center;
    margin-right: 20px;
  } */
.red-dot-margin-2 {
    margin-left: -17px;
}

.red-dot-margin-1 {
    text-align: center;
    margin-left: -17px;
}

.btn-contaiener {
    display: flex;
    justify-content: space-evenly;
}

.cancel-btn {
    color: var(--02, #848484);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--02, #848484);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-right: 21px;
}

.btn-download {
    display: flex;
    align-items: center;
    gap: 5px;
}

.delete-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.snackbar-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    margin-right: auto;
    padding: 14px 16px;
    text-align: initial;
    cursor: pointer;

}

.save-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #00b800);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
}

.del-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #b80900);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-left: 30px;
}

.text-limit {
    width: 30ch;
    /* Отображение примерно 10 символов */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--Gray-1, #333);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
}

.horizontal-line {
    /* Ширина div'а будет равна 100% от родительского контейнера */
    height: 1px;
    /* Толщина линии */
    background: #848484;
    /* Цвет линии (в данном случае черный) */
}

.custom-select {
    position: relative;
    width: 350px;
    cursor: pointer;
    /* margin-left: 9px; */
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
}

.select-arrow {
    position: absolute;
    top: 40%;
    right: 30px;
    /* transform: translateY(-50%); */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    /* Цвет стрелочки */
}

.col-description,
:focus {
    outline: none;
    /* Убираем внешний контур (outline) при фокусе */
    border: none;
    /* Убираем границу (border) при фокусе */
    box-shadow: none;
    /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
    color: #e41313;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.col-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    margin-bottom: 0px;
}

.col-paddings-r-l {
    padding-right: 0px;
    padding-left: 0px;
}

.col-content {
    display: flex;
    align-items: flex-start;
}

.role-margin {
    margin-right: 37px;
}

.role-margin-1 {
    margin-right: 30px;
}

.col-content-2 {
    display: flex;
    align-items: center;
    width: fit-content;
}

.col-description {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
    word-wrap: break-word;
    resize: none;
    height: 30px;
    /* min-height: 20px; */
    width: 100%;
    /* margin-left: 18px; */
}

::v-deep .v-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: none;
}

.modal-title {
    color: var(--gray-1, #333);
    font-family: Inter !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.page-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.vertical-line {
    width: 1px;
    height: 42px;
    background: #e0e0e0;
    margin-left: 31px;
}

.vertical-line-1 {
    width: 1px;
    height: 50px;
    background: #e0e0e0;
}

.add-btn .add-text {
    color: #Fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 12px;
    transition: color 0.3s ease;
}

.add-btn:hover .add-text {
    /* Цвет текста при наведении */
    color: white;
    /* Замените "red" на желаемый цвет текста */
    /* font-weight: 500;
    font-size: 17px; */
}

.add-btn {
    display: flex;
    align-items: center;
    padding: 5px 18px 5px 18px;
    margin-left: 37px;
    cursor: pointer;
    border-radius: 5px;
    background: rgb(50, 60, 77);
    height: 36px;
}

.top-panel {
    /* height: 50px; */
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.search-container {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-pluss-btn {
    margin-left: 10px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-notfound {
    /* display: flex; */
    margin: 0 auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.search-component {
    /* max-width: 430px;
    width: 100%; */
    height: 45px;
    align-items: center;
    display: inline-block;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    gap: 157px;
    margin-top: 12px;
    width: 100%;
    max-width: 425px;
}

.search-component__input {
    width: 100%;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    max-width: 425px;
    outline: unset;
    padding: 10px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    max-width: 425px;
}

.chart-name-container {
    width: fit-content;
}

.search-component__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* отступ от правого края */
    transform: translateY(-50%);
    pointer-events: none;
    /* чтобы клики не перехватывались SVG */
}

#staff-add-button {
    border-radius: 8px;
    background-color: #333;
    color: var(--white, #fff);
    gap: 12px;
    padding: 10px 56px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 45px;
}

::v-deep .guest-grid tbody tr:nth-child(2n) {
    background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    color: var(--gray-1, #333) !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
    color: #606C80 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: 2px solid #606C80;
}

.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #98a2b2;
    /* Серый цвет */
    /* border-radius: 10px; */
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #323c4d;
    /* Красный цвет заполнения */
    width: 0;
    transition: width 0.3s ease;
}

.card-bonus {
    display: flex;
    align-items: center;
}

.bonus-points {
    font-family: Inter;
    font-size: 30.62px;
    font-weight: 700;
    /* line-height: 37.06px; */
    text-align: left;
    color: #323C4D;
    margin-bottom: 0px;
    margin-right: 5px;
}

.first-order-container {
    margin-top: 10px;
}

.register-container {
    margin-top: 14px;
    margin-bottom: 27px;
}

.birthday-container {
    margin-bottom: 21px;
}

.gender-container {
    margin-bottom: 15px;
}

.phone-container {
    margin-top: 12px;
    margin-bottom: 15px;
}

.small-icons {
    width: 20px;
    height: 20px;
}

.user-column {
    display: flex;
    flex-direction: column;
}

.avatar-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #606c80;
}






#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #606c80;
}

.location-containers {
    margin-bottom: 19px;
    display: flex;
    margin-top: 12px;
    flex-direction: column;


}

.location-container {

    overflow-y: auto;
    overscroll-behavior: none;


}

.location-info {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.location-adress {
    font-family: Inter;
    font-weight: 500;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.location-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 7px;
}

.third-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 388px;
}

.order-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    color: #606c80;
    margin-bottom: 0px;
}

.order-info-text-second {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    /* text-align: right; */
    color: #323c4d;
    margin-bottom: 0px;
}

.order-info-containers {
    display: flex;
    justify-content: space-between;
}

.order-info-containers-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.order-info-text-green {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #00b800;
    margin-bottom: 0px;
    padding: 0 10px;

}

.order-info-container {
    margin-top: 24px;
}

.date-containers {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}

.date-container-1 {
    max-width: 121px;
    width: 100%;
}

.date-container-2 {
    max-width: 115px;
    width: 100%;
    margin-right: 74px;
}


.date-order {
    font-family: inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.order-1 {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.divider-modal {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;

    /* Расширяем на всю ширину родительского контейнера */
}

.review-img {
    width: 22px;
    padding-right: 5.8px;
}

.review-container {
    display: flex;
    align-items: center;
}

.review-text {

    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    color: #606c80;

}

.review-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #606c80;
    margin-bottom: 0px;
    padding-right: 10px;
}

.title-name {
    font-family: Inter;
    font-weight: 500;
    font-size: 15pxem;
    color: #323c4d;
    margin-bottom: 0px;
    padding-left: 14px;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-card {
    padding: 10px 26px 38px;
    background-color: #F8F8F8 !important;
}

.card-username {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /* line-height: 19.36px; */
    text-align: center;
    margin-bottom: 0px;
    margin-top: 8px;

}

.card-item-second {
    background: #fff;
    max-width: 388px;
    width: 100%;
    border-radius: 5px;
    height: 683px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: 15px 13px;

}

.card-item {
    background: #fff;
    /* max-width: 247px; */
    width: 388px;
    border-radius: 5px;
    /* height: 620px; */
    display: flex;
    flex-direction: column;
    padding: 15px 25px 73px;

}

.card-item-3 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    /* height: 35.2777777778vh; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
}

.card-item-4 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    height: 231px;
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.card2-title {
    margin: 0;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

}



.card-cashback {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #98A2B2;
}

.bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price-circle {

    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #323C4D;

}

.price-circle-gray {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.price-line-gray {
    background-color: #98A2B2;

    width: 347px;
    height: 2px;
    margin-top: -8px;

}

.price-line-fill {
    width: 260px;
    height: 5px;
    background-color: #323C4D;
    border-radius: 4px;

    margin-top: -4px;
}

.price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.price-container p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #98A2B2;
}

.price-remains {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

    color: #98A2B2;
}



.card-label {
    color: #606C80;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 2px; */
    text-align: left;
    /* margin-top: 8px; */
    margin-bottom: 5px;

}

.card-text {
    color: #333333;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    margin-bottom: 0px;
}

.card-line {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;
}

.modal-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;

}

.modal-header {
    padding: 16px 5px 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    padding-top: 15px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
}

::v-deep .v-dialog {
    margin: 0px;
}

.cashback-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

    color: #323C4D;
}

.chart-container {
    position: relative;
}

.chart-circle-container {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;

    left: -12.5px;
}

.chart-circle-gray {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    border-radius: 50%;
    background-color: #98A2B2;
}

.chart-circle-container div {
    margin-bottom: 40px;
}

.cashback-line-gray {
    width: 3px;
    height: 280px;
    background-color: #9F9F9F;
}

.cashback-line-fill {
    /* position: relative; */
    /* top: 90px; */
    margin-top: -200px;
    width: 6px;

    margin-left: -1.5px;
    border-radius: 4px;
    background-color: #323C4D;
}

.cashback-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    width: 50%;
}

.cashback-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.cashback-info div {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    margin-left: 12.5px;
}

.cashback-info h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 5px;
}

.cashback-info p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin: 0;
    margin-left: 5px;

    color: #606C80;
}

.cashback-prices p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 0px; */
    text-align: left;
    margin-bottom: 72.5px;

    /* margin: 0; */
    color: #606C80;
}

#custom-toolbar ::v-deep .v-toolbar__content {
    padding-left: 0 !important;
    /* Убирает отступ слева */
}


.chart-circle-gray-container {}

.chart-circle-gray-container {}

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}

@media (max-width: 1600px) {
    .block {
        width: calc(50% - 11px);
        /* 50% ширины с учётом gap */
    }
}

@media (max-width: 1146px) {
    .block {
        width: calc(19.5% - 11px);
        /* 50% ширины с учётом gap */
    }

    .block-title {
        font-size: 1.1vw;
    }
}

@media (max-width: 768px) {
    .block {
        width: 100%;
        /* Полная ширина на мобильных устройствах */
    }
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
    color: pink !important;
    background-color: #00b800 !important;
}

::v-deep .v-autocomplete__content {
    background-color: black;
    /* Устанавливаем черный цвет для фона меню */
    color: white;
    /* Устанавливаем белый цвет для текста в меню */
}</style>